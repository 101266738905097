import { useTranslation } from 'react-i18next'
import { MatrixData } from './Matrix'
import { useState } from 'preact/hooks'
import type { SummaryData } from './DrillingSummary'
import type { ChangeEvent, StateUpdater } from 'preact/compat'
import DrillingSummary from './DrillingSummary'
import type { HoleDescription } from './HoleDescriptionForm'
import HoleDescriptionForm from './HoleDescriptionForm'
import { getBorers } from '@/api/resources'
import { useQuery } from '@tanstack/react-query'
import { calculateSummary, initHolesArray } from './utils'
import Btn from '@/components/forms/Btn'
import CollapsibleFieldset from '@/components/CollapsibleFieldset'
import { h, Fragment } from 'preact';
import { setNestedValue } from '@/utils'

export const defaultHoleDescription: HoleDescription = {
    hole_number: '',
    is_off_contract: true,
    trephine_number: '',
    diameter: 0,
    hammer: '',
    angle: 0,
    depth_planned: {
        value: 0,
        use_for_calculations: true
    },
    matrix_input: 0,
    drilled: {
        value: 0,
        use_for_calculations: false
    },
    redrilled: {
        value: 0,
        use_for_calculations: false
    },
    cleaned: {
        value: 0,
        use_for_calculations: false
    },
    depth_measured: 0,
    broken_rocks: 0,
    casing_meters: 0,
    is_remote: false,
    is_water: false,
    is_pre_cutting: false,
    is_geotechnical_limit: false,
    result: '',
    comment: '',
    is_collapsed: false,
    is_hole_number_changed: false,
}

type Props = {
    name: string
    value: {
        matrixData: MatrixData,
        summaryComment: string
    }  | undefined
    units: string
    drillingType: string
    defaultCollapsed?: boolean
}

const MineDrilling: FC<Props> = ({ ...p }) =>  {
	const { t } = useTranslation('driller')
    const { data: borers } = useQuery<Api.Equipment[]>({
        queryKey: ['borers'],
        queryFn: getBorers
    })
    const [hasMatrix, setHasMatrix] = useState(false)
    const [matrixData, setMatrixData] = useState<MatrixData>(p.value ? p.value.matrixData : {
        cols: 0,
        rows: 0,
        burden: 0,
        spacing: 0,
        holeDescriptions: initHolesArray(0, 0),
    })

    const handleInput = (holeIndex: number, field: string, value: number | string |  boolean) => {
        const newHoleDescriptions = [...matrixData.holeDescriptions]
        const holeDescription = newHoleDescriptions.find((val, i) => {
            return i == holeIndex
        })

        if (holeDescription) {
            setNestedValue(holeDescription, field, value)
            if (field == 'hole_number') {
                holeDescription.is_hole_number_changed = true
            }
        }

        console.log('newHoleDescriptions', newHoleDescriptions)

        setMatrixData({...matrixData, holeDescriptions: newHoleDescriptions})
    }

    const addHole = (e: Event) => {
        matrixData.holeDescriptions.map((hole: HoleDescription, i) => {
            hole.is_collapsed = true
        })

        const newHole = JSON.parse(JSON.stringify(defaultHoleDescription))
        const lastHole = matrixData.holeDescriptions[matrixData.holeDescriptions.length - 1]
        if (lastHole) {
            newHole.trephine_number = lastHole.trephine_number
            newHole.hammer = lastHole.hammer
            newHole.diameter = lastHole.diameter
            newHole.angle = lastHole.angle
        }

        setMatrixData({...matrixData, holeDescriptions: [...matrixData.holeDescriptions, newHole]})
    }

    const toggleCollapse = (e: Event, moveUp: boolean, index: number) => {
        const newHoleDescriptions = [...matrixData.holeDescriptions]
        newHoleDescriptions[index].isCollapsed = !newHoleDescriptions[index].isCollapsed
        setMatrixData({...matrixData, holeDescriptions: newHoleDescriptions})
    }

    const handleUseForCalculationsSelect = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        e.preventDefault()
        const newHoleDescriptions = [...matrixData.holeDescriptions]
        const newHoleValue = newHoleDescriptions[index]
        const name = (e.target as HTMLInputElement).name
        const field = name.split('[')[3].split(']')[0]

        if (field == 'depth_planned') {
            newHoleValue.drilled.use_for_calculations = false
            newHoleValue.redrilled.use_for_calculations = false
            newHoleValue.cleaned.use_for_calculations = false
        } else {
            newHoleValue.depth_planned.use_for_calculations = false
        }
        newHoleValue[field].use_for_calculations = !newHoleValue[field].use_for_calculations

        if (newHoleValue.depth_planned.use_for_calculations == false && newHoleValue.drilled.use_for_calculations == false && newHoleValue.redrilled.use_for_calculations == false && newHoleValue.cleaned.use_for_calculations == false) {
            newHoleValue.is_off_contract = false
        } else {
            newHoleValue.is_off_contract = true
        }

        setMatrixData({...matrixData, holeDescriptions: newHoleDescriptions})
    }

    const handleMatrixInput = (index: number, value: number, matrixValue: number) => {
        const newHoleDescriptions = matrixData.holeDescriptions

        newHoleDescriptions[index].depth_planned.value = value
        newHoleDescriptions[index].matrix_input = matrixValue

        setMatrixData({...matrixData, holeDescriptions: newHoleDescriptions})
    }

    const handleResize = (field: string, value: number): void => {
        let newHoleDescriptions = matrixData.holeDescriptions
        const cols = field == 'cols' ? value : matrixData.cols
        const rows = field == 'rows' ? value : matrixData.rows
        const length = cols * rows

        if (cols > 26 || rows > 26) {
            setMatrixData({...matrixData})
            return
        }

        if (newHoleDescriptions.length < length) {
            Array.from(Array(Math.abs(newHoleDescriptions.length - length))).forEach((val, i) => {
                newHoleDescriptions = [...newHoleDescriptions, structuredClone(defaultHoleDescription)]
            })
        } else if (newHoleDescriptions.length > length) {
            newHoleDescriptions = newHoleDescriptions.slice(0, length)
        }

        newHoleDescriptions.map((hole, i) => {
            if (!hole.isHoleNumberChanged) {
                hole.hole_number = String.fromCharCode(Math.ceil((i + 1) / cols) + 64) + ((i % cols) + 1)
            }
        })

        setMatrixData({...matrixData, [field]: value, holeDescriptions: newHoleDescriptions})
    }

    const handleFormTypeSelect = (e: ChangeEvent) => {
        const type = (e.target as HTMLInputElement).value
        if (type == '') {
            return
        } else if (type == 'mine') {
            setMatrixData({
                cols: 0,
                rows: 0,
                burden: 0,
                spacing: 0,
                holeDescriptions: initHolesArray(0, 0),
            })
        } else {
            setMatrixData({
                cols: 2,
                rows: 2,
                burden: 0,
                spacing: 0,
                holeDescriptions: initHolesArray(2, 2),
            })
        }
    }

    const summary: SummaryData = calculateSummary(matrixData)

	return (
		<Fragment>
            <CollapsibleFieldset
                title={t('hole_description')}
                defaultCollapsed={p.defaultCollapsed}
            >
                <div class='flex flex-col gap-y-8'>
                    {matrixData.holeDescriptions?.map((val, i) =>
                        <CollapsibleFieldset
                            title={t('hole') + ' ' + (i + 1) + ' - ' + val.hole_number}
                            isCollapsed={val.isCollapsed}
                            className={'m-0'}
                            toggleCollapse={toggleCollapse}
                            index={i}
                        >
                            <HoleDescriptionForm
                                index={i}
                                hole={val}
                                handleInput={handleInput}
                                borers={borers}
                                units={p.units}
                                handleUseForCalculationsSelect={handleUseForCalculationsSelect}
                            />
                        </CollapsibleFieldset>
                    )}
                    <div class='flex justify-end gap-x-2'>
                        {matrixData.holeDescriptions?.length && p.drillingType == 'mine' ? (
                            <Btn danger onClick={() => setMatrixData({...matrixData, holeDescriptions: matrixData.holeDescriptions.slice(0, -1)})}>
                                {t('forms:remove')}
                            </Btn>
                        ) : ''}
                        {
                            p.drillingType == 'mine' ? (
                                <Btn onClick={addHole}>{t('forms:add')}</Btn>
                            ) : ''
                        }
                    </div>
                </div>
            </CollapsibleFieldset>
                
            <DrillingSummary
				summary={summary}
                units={p.units}
                comment={p.value ? p.value.summaryComment : ''}
			/>
                
		</Fragment>
	)
}

export default MineDrilling