import Input from "@/components/forms/Input"
import type { ChangeEvent } from "preact/compat"
import Options from "@/components/forms/Options"
import { useTranslation } from 'react-i18next'

export type HoleDescription = {
    [key: string]: any
    hole_number: string
    is_off_contract: boolean
    trephine_number: string
    diameter: number
    hammer: string
    angle: number
    depth_planned: {
        value: number,
        use_for_calculations: boolean
    }
    drilled: {
        value: number
        use_for_calculations: boolean
    }
    redrilled: {
        value: number
        use_for_calculations: boolean
    }
    cleaned: {
        value: number
        use_for_calculations: boolean
    }
    depth_measured: number
    broken_rocks: number
    casing_meters: number
    is_remote: boolean
    is_water: boolean
    is_pre_cutting: boolean
    is_geotechnical_limit: boolean
    result: string
    comment: string
    matrix_input: number
    is_collapsed: boolean,
    is_hole_number_changed: boolean
}

type Props = {
    hole: HoleDescription
    index: number
    handleInput: (index: number, field: string, value: string | number | boolean) => void
    handleUseForCalculationsSelect: (e: ChangeEvent, index: number) => void
    borers: Api.Equipment[] | undefined
    units: string
}

const HoleDescriptionForm: FC<Props> = ({hole, index, handleInput, ...p}) => {
    const { t } = useTranslation('driller')

    return (
        <>
            <input type="hidden" name={`form[mine][${index}][is_off_contract]`} checked={hole.is_off_contract} />
            <Input
                className='span6'
                type='text'
                value={hole.hole_number}
                name={`form[mine][${index}][hole_number]`}
                label={t('hole_number')}
                onChange={(e: ChangeEvent) => handleInput(index, 'hole_number', (e.target as HTMLInputElement).value)}
            />
            <Input
                className='span3-nb'
                type='text'
                value={hole.trephine_number}
                name={`form[mine][${index}][trephine_number]`}
                label={t('trephine_number')}
                onChange={(e: ChangeEvent) => handleInput(index, 'trephine_number', (e.target as HTMLInputElement).value)}
            />
            <Input
                className='span3-nb'
                type='text'
                value={hole.hammer}
                name={`form[mine][${index}][hammer]`}
                label={t('hammer')}
                onChange={(e: ChangeEvent) => handleInput(index, 'hammer', (e.target as HTMLInputElement).value)}
            />
            <Input
                className='span3-nb'
                type='number'
                decimal
                value={hole.diameter}
                name={`form[mine][${index}][diameter]`}
                label={t('diameter')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'diameter', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />        
            <Input
                className='span3-nb'
                type='text'
                value={hole.angle}
                decimal
                name={`form[mine][${index}][angle]`}
                label={t('angle')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'angle', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />    
            <Input
                className='span2-nb'
                type='number'
                decimal
                value={hole.depth_planned.value}
                name={`form[mine][${index}][depth_planned][value]`}
                label={t('planned')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'depth_planned.value', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />
            <div class={'span4-nb flex items-center'}>
                <label class="text-xs mr-1" htmlFor={`planned-production-calculations-${index}`}>
                    {t('production_calculations')}
                </label>
                <input
                    type="checkbox"
                    checked={hole.depth_planned.use_for_calculations}
                    id={`planned-production-calculations-${index}`}
                    class={'span3-nb'}
                    name={`form[mine][${index}][depth_planned][use_for_calculations]`}
                    onChange={(e) => p.handleUseForCalculationsSelect(e, index)}
                 />
            </div>
            <Input
                className='span2-nb'
                type='number'
                decimal
                value={hole.drilled.value}
                name={`form[mine][${index}][drilled][value]`}
                label={t('drilled')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'drilled.value', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />
            <div class={'span4-nb flex items-center'}>
                <label class="text-xs mr-1" htmlFor={`drilled-production-calculations-${index}`}>
                    {t('production_calculations')}
                </label>
                <input
                    type="checkbox"
                    checked={hole.drilled.use_for_calculations}
                    id={`drilled-production-calculations-${index}`}
                    class={'span3-nb'}
                    name={`form[mine][${index}][drilled][use_for_calculations]`}
                    onChange={(e) => p.handleUseForCalculationsSelect(e, index)}
                />
            </div>
            <Input
                className='span2-nb'
                type='number'
                decimal
                value={hole.redrilled.value}
                name={`form[mine][${index}][redrilled][value]`}
                label={t('redrilled')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'redrilled.value', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />
            <div class={'span4-nb flex items-center'}>
                <label class="text-xs mr-1" htmlFor={`redrilled-production-calculations-${index}`}>
                    {t('production_calculations')}
                </label>
                <input
                    type="checkbox"
                    checked={hole.redrilled.use_for_calculations}
                    id={`redrilled-production-calculations-${index}`}
                    class={'span3-nb'}
                    name={`form[mine][${index}][redrilled][use_for_calculations]`}
                    onChange={(e) => p.handleUseForCalculationsSelect(e, index)}
                />
            </div>
            <Input
                className='span2-nb'
                type='number'
                decimal
                value={hole.cleaned.value}
                name={`form[mine][${index}][cleaned][value]`}
                label={t('cleaned')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'cleaned.value', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />
            <div class={'span4-nb flex items-center'}>
                <label class="text-xs mr-1" htmlFor={`cleaned-production-calculations-${index}`}>
                    {t('production_calculations')}
                </label>
                <input
                    type="checkbox"
                    checked={hole.cleaned.use_for_calculations}
                    id={`cleaned-production-calculations-${index}`}
                    class={'span3-nb'}
                    name={`form[mine][${index}][cleaned][use_for_calculations]`}
                    onChange={(e) => p.handleUseForCalculationsSelect(e, index)}
                />
            </div>
            <Input
                className='span3-nb'
                type='number'
                decimal
                value={hole.depth_measured}
                name={`form[mine][${index}][depth_measured]`}
                label={t('measured')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'depth_measured', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />

            <Input
                className='span3-nb'
                type='number'
                decimal
                value={hole.broken_rocks}
                name={`form[mine][${index}][broken_rocks]`}
                label={t('broken_rocks')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'broken_rocks', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />

            <Input
                className='span6'
                decimal
                value={hole.casing_meters}
                name={`form[mine][${index}][casing_meters]`}
                label={t('casing_meters')}
                onBlur={(e: ChangeEvent) => handleInput(index, 'casing_meters', (e.target as HTMLInputElement).value == '' ? 0 : parseFloat((e.target as HTMLInputElement).value))}
            />

            <div class={'span3-nb'}>
                <label class="text-xs mr-1" htmlFor={`remote-${index}`}>
                    {t('remote')}
                </label>
                <input type="checkbox" checked={hole.is_remote} id={`remote-${index}`} class={'span3-nb'} name={`form[mine][${index}][is_remote]`} onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(index, 'is_remote', (e.target as HTMLInputElement)?.checked)}/>
            </div>

            <div class={'span3-nb'}>
                <label class="text-xs mr-1" htmlFor={`water-${index}`}>
                    {t('water')}
                </label>
                <input type="checkbox" checked={hole.is_water} id={`water-${index}`} class={'span3-nb'} name={`form[mine][${index}][is_water]`} onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(index, 'is_water', (e.target as HTMLInputElement)?.checked)}/>
            </div>

            <div class={'span3-nb'}>
                <label class="text-xs mr-1" htmlFor={`pre-cutting-${index}`}>
                    {t('pre_cutting')}
                </label>
                <input type="checkbox" checked={hole.is_pre_cutting} id={`pre-cutting-${index}`} class={'span3-nb'} name={`form[mine][${index}][is_pre_cutting]`} onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(index, 'is_pre_cutting', (e.target as HTMLInputElement)?.checked)}/>
            </div>

            <div class={'span3-nb'}>
                <label class="text-xs mr-1" htmlFor={`geotechnical-limit-${index}`}>
                    {t('geotechnical_limit')}
                </label>
                <input type="checkbox" checked={hole.is_geotechnical_limit} id={`geotechnical-limit-${index}`} class={'span3-nb'} name={`form[mine][${index}][is_geotechnical_limit]`} onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(index, 'is_geotechnical_limit', (e.target as HTMLInputElement)?.checked)}/>
            </div>

            <label>
                {t('result')}
                <select name={`form[mine][${index}][result]`} onChange={(e: ChangeEvent) => handleInput(index, 'result', (e.target as HTMLInputElement).value)}>  
                    <Options d={hole.result} t={t} options={['NBT - Non Break Through - Dans le roc solide', 'TC - Tout Cassé - Dans la roche cassée', 'BT - Break Through - Défonçant dans le vide', 'BTM - Break Through Muck - Défonçant dans la roche cassées']}/>
                </select>
            </label>

            <Input
                className='span6'
                type='text'
                value={hole.comment}
                name={`form[mine][${index}][comment]`}
                label={t('forms:comments')}
                onChange={(e: ChangeEvent) => handleInput(index, 'comment', (e.target as HTMLInputElement).value)}
            />
        </>

    )
}

export default HoleDescriptionForm
